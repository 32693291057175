<template >
  <div v-if="$route.name == 'main'" class="video">
     <video autoplay muted loop>
       <source src="@/assets/img/main_video.mp4" type="video/webm" />
       <source src="@/assets/img/main_video.mp4" type="video/mp4" />
     </video>
  </div>
  <main-header v-if="$route.name !== 'partnerCashList' && $route.name !== 'partnerBettingList' && $route.name !== 'partnerList' && $route.name !== 'partnerCalculationList' && $route.name !== 'partnerCalculationUserList' && $route.name !== 'partnerMemberList' && $route.name !== 'partnerMoneyMoveList'" />

  <router-view :key="$route.fullPath"></router-view>
  <main-footer v-if="banner" />
  <slot-list v-if="isSlotOpen" :groupCode="groupCode" :codeName="codeName" :code="code" @isSlot="isSlotOpen = false"></slot-list>
  <loading></loading>
</template>

<script>
import MainHeader from './Header'
import MainFooter from './Footer'
import SlotList from '@/components/game/SlotList'
import { mapState } from 'vuex'
import Loading from '@/components/common/Loading.vue'

export default {
  name: 'DefaultLayout',
  components: {
    Loading,
    SlotList,
    MainHeader,
    MainFooter
  },
  computed: {
    ...mapState([
      'banner'
    ])
  },
  mounted () {
    this.emitter.on('isSlot', (data) => {
      if (data) {
        const groupCode = data.groupCode
        const codeName = data.codeName
        const code = data.code
        this.getSlotOpen(groupCode, codeName, code)
      } else {
        this.isSlotOpen = false
      }
    })
  },
  data () {
    return {
      isSlotOpen: false,
      groupCode: '',
      codeName: '',
      code: '',
      isLoading: false
    }
  },
  methods: {
    getSlotOpen (groupCode, codeName, code) {
      if (this.userData) {
        this.groupCode = groupCode
        this.codeName = codeName
        this.code = code
        this.isSlotOpen = true
      } else {
        this.onCheck('front.error.afterSignin')
      }
    }
  }
}
</script>

<style>
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  transition: opacity 0.5s ease-in;
}

.fade-leave-active {
  transition: opacity 0.2s ease-in;
}

.fade-leave-to {
  opacity: 0;
}

.slide-fade-enter {
  transform: translateX(10px);
  opacity: 0;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.2s ease;
}

.slide-fade-leave-to {
  transform: translateX(-10px);
  opacity: 0;
}

.slide-up-enter {
  transform: translateY(185px);
  opacity: 0;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.3s ease;
}

.slide-up-leave-to {
  transform: translateY(0px);
  opacity: 0;
}
.video {width: 100%;max-height: 520px;overflow: hidden;position: relative;}
.video video {height: 100%;width: 100%;object-fit: cover;vertical-align: bottom;}
</style>
